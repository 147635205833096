<template>
  <div class="top-content">
    <div class="bottom">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" label-width="96px" class="ruleForm" :size="formSize">
        <el-form-item label="完成时间：" prop="desc">
          <el-date-picker @change="handleDateChange" v-model="ruleForm.date" type="daterange" range-separator="到"
            start-placeholder="开始时间" end-placeholder="结束时间" />
        </el-form-item>

        <el-form-item label="下单门店：" prop="desc">
          <el-select clearable @change="handleShopChange" v-model="ruleForm.shopId" class="m-2" placeholder="选择门店">
            <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="订单来源：" prop="desc">
          <el-select clearable v-model="ruleForm.source" class="m-2" placeholder="全部">
            <el-option v-for="item in sourceCategory" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="订单类型：" prop="desc">
          <el-select clearable v-model="ruleForm.orderType" class="m-2" placeholder="全部">
            <el-option v-for="item in orderCategory" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="付款方式：" prop="desc">
          <el-select clearable v-model="ruleForm.paykind" class="m-2" placeholder="全部">
            <el-option v-for="item in payCategory" :key="item.payWay" :label="item.payWayName" :value="item.payWay">
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="支付状态：" prop="desc">
          <el-select clearable v-model="ruleForm.payStatus" class="m-2" placeholder="全部">
            <el-option v-for="item in paystatusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label-width="120px" label="订单处理状态：" prop="desc">
          <el-select @change="handleorderstatusChange" clearable v-model="orderstatus" class="m-2" placeholder="全部">
            <el-option v-for="item in orderStatusList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <div class="right">
          <div>
            <el-input @change="handleSearchChange" clearable class="search" prefix-icon="search"
              v-model="ruleForm.keyword" placeholder="商品/服务名称，编码等" />
          </div>
          <div>
            <el-button @click="handleSearchClick" type="primary">搜索</el-button>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import emitter from '@/utils/eventbus'
import { COMPANY_ID } from '@/common/constant'
import { useStore } from 'vuex'
import dayjs from 'dayjs'
import { orderCategory, orderStatusList, paystatusList, sourceCategory } from '../../config'
import {getAllPayWay} from '@/service/main/order'
export default defineComponent({
  props: {

  },
  components: {

  },
  emits: ['searchChange'],
  setup(props, { emit }) {
    const store = useStore()
    const orderstatus = ref('')
    const payCategory = ref([])

    const ruleForm = reactive({
      // companyId: COMPANY_ID,
      // keyword: '',
      // source: '',
      // orderType: '',
      // paykind: '',
      // shopId: '',
      // page: 1,
      // startDate: '',
      // endDate: '',
      // userid: '',
      // payStatus: '',
      // orderstatus: '',
      // uncomment: "",
      // date: '',
      // listtype: 0,
      // status: ''
      current: 1,
      size: 10
    })

    // emitter.on('changeAllOrderListPageResersh', page => {
    //   ruleForm.page = page
    //   store.dispatch('order/getOrderListAction', { ...ruleForm })
    // })

    const handleorderstatusChange = value => {
      if (value == 0) {
        ruleForm.uncomment = ''
        ruleForm.status = ''
        ruleForm.appStatus = ''
      } else if (value == 1) {
        ruleForm.appStatus = 1
      } else if (value == 2) {
        ruleForm.payStatus == 1
        ruleForm.status = 1
      } else if (value == 3) {
        ruleForm.payStatus == 2
        ruleForm.appStatus = 4
      } else if (value == 4) {
        ruleForm.payStatus == 2
        ruleForm.appStatus = 4
        ruleForm.uncomment = 1
      } else if (value == 5) {
        ruleForm.status = 2
      }
    }

    const handleSearchClick = () => {
      // emitter.emit('changeOrderListSearchContent')
      emit('searchChange',ruleForm)
      // store.dispatch('order/getOrderListAction', { ...ruleForm })
    }

    const handleSearchChange = value => {
      if (value.length == 0) {
        ruleForm.keyword = ''
        // emitter.emit('changeOrderListSearchContent')
        // store.dispatch('order/getOrderListAction', { ...ruleForm })
      }
    }

    const handleDateChange = date => {
      if (date != null) {
        ruleForm.startDate = dayjs(date[0]).format('YYYY-MM-DD')
        ruleForm.endDate = dayjs(date[1]).format('YYYY-MM-DD')
      }else{
        ruleForm.startDate=undefined
        ruleForm.endDate=undefined
      }

    }

    // emitter.on('changeOrderListPageInfo', page => {
    //   ruleForm.page = page
    //   store.dispatch('order/getOrderListAction', { ...ruleForm })
    // })

    const shopList = computed(() => store.state.reserve.shopList)

    const getThreeMonthAgoDate = () => {
      let threeMonthsAgoDate = dayjs(new Date()).subtract(3, "months").format("YYYY-MM-DD");
      return threeMonthsAgoDate;
    }

    const initPage = async() => {
      store.dispatch('reserve/getShopListAction2', COMPANY_ID)
      let data = await getAllPayWay()
      ruleForm.date=[getThreeMonthAgoDate(),dayjs(new Date()).format("YYYY-MM-DD")]
      ruleForm.startDate=getThreeMonthAgoDate()
      ruleForm.endDate=dayjs(new Date()).format("YYYY-MM-DD")
      payCategory.value = data.data
      // store.dispatch('order/getOrderListAction', { ...ruleForm })
    }
    
    initPage()

    onUnmounted(() => {
      // emitter.off('changeOrderListPageInfo')
      // emitter.off('changeAllOrderListPageResersh')
    })

    return {
      sourceCategory,
      paystatusList,
      orderStatusList,
      payCategory,
      orderCategory,
      shopList,
      handleDateChange,
      ruleForm,
      handleSearchClick,
      orderstatus,
      handleorderstatusChange,
      handleSearchChange
    }

  }
})
</script>

<style scoped lang="less">
.top-content {
  background-color: #fff;
  // padding: 20px;
  // margin: 20px 20px 10px 20px;
  min-width: 960px;



  .bottom {
    margin-top: 20px;

    .right {
      display: flex;
      padding-left: 10px;
      .search {
        max-width: 220px;
        min-width: 180px;
        margin: 0 10px;
      }
    }

    .ruleForm {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
</style>





<template>
  <div class="order-tabel">
    <div class="title">
      <template :key="item" v-for="(item,index) in orderTitleInfoList">
        <div :style="index==0?'flex:1.6':index==1?'flex:0.6':''">{{ item }}</div>
      </template>
    </div>
    <div class="order-info-list">
      <div v-if="orderList.length > 0">
        <template :key="item.code" v-for="item in orderList">
          <div class="order-info-item">
            <div class="top">
              <div class="left">
                <div class="buy-time">完成时间：{{ item.payTime }}</div>
                <div class="order-num">订单编号：{{ item.code }}</div>
              </div>
              <div class="right"></div>
            </div>
            <div class="bottom">
              <div class="avatar-price-buynum">
                <template v-if="item.type == 1">
                  <template :key="serveAvatar" v-for="serveAvatar in item.itemList">
                    <div class="avatar-price-buynum-item">
                      <div class="avatar" style="flex:2.5;">
                        <div class="serveAvatar-item">
                          <!-- <template> -->
                            <img :src="serveAvatar.productPicture" alt />
                          <!-- </template> -->
                          <!-- <template v-if="item.orderKind == 3">
                            <img src="@/assets/img/main/card/card1.png" alt />
                          </template>
                          <template v-if="item.orderKind == 4">
                            <img src="@/assets/img/main/card/card2.png" alt />
                          </template>
                          <template v-if="item.orderKind == 5">
                            <img src="@/assets/img/main/card/card3.png" alt />
                          </template>
                          <template v-if="item.orderKind == 7">
                            <img src="@/assets/img/main/card/card4.png" alt />
                          </template>
                          <template v-if="item.orderKind == 8">
                            <img src="@/assets/img/main/card/card5.png" alt />
                          </template> -->

                          <div class="goods_name">
                            <div class="itemName">{{ serveAvatar.productName }}</div>
                            <div>
                              <!-- <template v-if="item.orderKind == 1 || item.orderKind == 2"> -->
                                <span class="el-tag el-tag--mini el-tag--plain"
                                  >服务</span
                                >
                              <!-- </template> -->
                              <!-- <template v-if="item.orderKind == 3 || item.orderKind == 4">
                                <span
                                  class="el-tag el-tag--success el-tag--mini el-tag--plain"
                                  >次卡</span
                                >
                              </template>
                              <template v-if="item.orderKind == 5">
                                <span
                                  class="el-tag el-tag--info el-tag--mini el-tag--plain"
                                  >储值卡</span
                                >
                              </template>
                              <template v-if="item.orderKind == 7">
                                <span
                                  class="el-tag el-tag--danger el-tag--mini el-tag--plain"
                                >
                                  余额充值</span
                                >
                              </template>
                              <template v-if="item.orderKind == 6">
                                <span
                                  class="el-tag el-tag--warning el-tag--mini el-tag--plain"
                                  >商品</span
                                >
                              </template> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="price">
                        <div>¥{{ serveAvatar.price.toFixed(2) }}</div>
                      </div>
                      <div class="but-num">
                        <div>
                          x{{ serveAvatar.count }}
                        </div>
                      </div>
                    </div>
                  </template>
                </template>

                <template v-else>
                  <template
                    :key="serveAvatar"
                    v-for="(serveAvatar, indez) in item.itemList"
                  >
                    <div class="avatar-price-buynum-item">
                      <div class="avatar" style="flex:2.5;">
                        <div class="serveAvatar-item">
                          <!-- <template v-if="serveAvatar.productType == 3">
                            <img src="@/assets/img/main/card/card1.png" alt />
                          </template>
                          <template v-if="serveAvatar.productType == 4">
                            <img src="@/assets/img/main/card/card2.png" alt />
                          </template>
                          <template v-if="serveAvatar.productType == 5">
                            <img src="@/assets/img/main/card/card3.png" alt />
                          </template>
                          <template v-if="serveAvatar.productType == 7">
                            <img src="@/assets/img/main/card/card4.png" alt />
                          </template> -->
                          <!-- <template> -->
                            <img src="@/assets/img/main/card/card5.png" alt />
                          <!-- </template> -->
                          <div class="goods_name">
                            <div class="itemName">{{ serveAvatar.productName }}</div>
                            <div>
                              <template v-if="item.type == 1">
                                <span class="el-tag el-tag--mini el-tag--plain"
                                  >服务</span
                                >
                              </template>
                              <template v-else>
                                <span
                                  class="el-tag el-tag--success el-tag--mini el-tag--plain"
                                  >购卡</span
                                >
                              </template>
                              <!-- <template v-if="item.orderKind == 5">
                                <span
                                  class="el-tag el-tag--info el-tag--mini el-tag--plain"
                                  >储值卡</span
                                >
                              </template>
                              <template v-if="item.orderKind == 7">
                                <span
                                  class="el-tag el-tag--danger el-tag--mini el-tag--plain"
                                >
                                  余额充值</span
                                >
                              </template>
                              <template v-if="item.orderKind == 6">
                                <span
                                  class="el-tag el-tag--warning el-tag--mini el-tag--plain"
                                  >商品</span
                                >
                              </template>
                              <template v-if="item.orderKind == 8">
                                <span
                                  class="el-tag el-tag--warning el-tag--mini"
                                  >折扣卡</span
                                >
                              </template> -->
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="price">
                        <div>¥{{ (serveAvatar.price).toFixed(2) }}</div>
                      </div>
                      <div class="but-num">
                        <div>x{{serveAvatar.count }}</div>
                      </div>
                    </div>
                  </template>
                </template>
              </div>

              <div class="teacher">
                <span>
                  {{ item.consumeName }}
                </span>
              </div>
              <div>
                <template v-if="item.isPoint">
                  <span class="el-tag el-tag--success el-tag--small el-tag--dark"
                    >是</span
                  >
                </template>
                <template v-else>
                  <span class="el-tag el-tag--info el-tag--small el-tag--dark">否</span>
                </template>
              </div>
              <div>
                <template
                  v-if="
                    (item.type == 2 && item.xukaFlag) ||
                    (item.type ==2 && item.firstCardOrder)
                  "
                >
                  <template v-if="item.xukaFlag">
                    <span class="el-tag el-tag--success el-tag--small el-tag--dark"
                      >续卡</span
                    >
                  </template>
                  <template v-if="item.firstCardOrder">
                    <span class="el-tag el-tag--success el-tag--small el-tag--dark"
                      >首购</span
                    >
                  </template>
                </template>
                <template v-else> - </template>
              </div>
              <div @click="handleUserClick(item)" class="user-name">
                {{ item.userName }}
              </div>

              <div class="total-price">
                  <div>¥{{ item.price.toFixed(2) }}</div>
              </div>
              <div class="buy-shop">
                <div>{{ item.shopName }}</div>
                <!-- <template v-if="item.roomInfo != null">
                  <div>
                    {{ item.roomInfo.roomNo }}<span>&</span>{{ item.roomInfo.bedId }}
                  </div>
                </template> -->
              </div>

              <div class="state">
                <!-- <template v-if="item.status == 1"> -->
                  <template v-if="item.payStatus == 1">
                    <span class="el-tag el-tag--warning el-tag--small el-tag--dark"
                      >待支付</span
                    >
                  </template>
                  <template v-if="item.payStatus == 2">
                    <span class="el-tag el-tag--success el-tag--small el-tag--dark"
                      >已支付</span
                    >
                  </template>
                <!-- </template> -->
                <!-- <template v-if="item.status == 2">
                  <span class="el-tag el-tag--info el-tag--small el-tag--dark"
                    >已取消</span
                  >
                </template> -->
                <template v-if="item.payStatus == 3">
                  <span class="el-tag el-tag--success el-tag--small el-tag--dark"
                    >已支付</span
                  >
                  <p style="marginTop:6px">（退款成功）</p>
                  <!-- <span class="el-tag el-tag--info el-tag--small el-tag--dark"
                    >已退款</span
                  > -->
                </template>
              </div>
              <div>
                <!-- <template v-if="item.buyItems&&item.buyItems.length > 0" class="handle"> -->
                  <!-- <el-button
                    @click="handleOpenkaidanClick(item)"
                    v-if="item.status == 1 && item.orderKind == 1 && item.payStatus == 1"
                    size="small"
                    plain
                    >开单
                  </el-button> -->
                  <el-button @click="handleDetailClick(item)" size="small" plain
                    >详情</el-button
                  >
                <!-- </template> -->
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else class="empty">
        <el-empty description="暂无相关数据"></el-empty>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { UPLOAD_BASE_URL } from '@/common/constant'

export default defineComponent({
  props: {
    orderList: {
      type: Array,
      default() {
        return []
      }
    },
    /*
    category
    0 订单
    1 跟进
    2 评价
    */
    category: {
      type: Number,
      default: 0
    }
  },
  components: {

  },
  emits: ['update:page'],
  setup(props, { emit }) {
    const store = useStore()
    const router = useRouter()

    const orderTitleInfoList = computed(() => store.state.order.orderTitleInfoList)

    const handleDetailClick = item => {
      router.push({
        path: '/main/order/detail',
        query: {
          id: item.id,
          type:item.type
        }
      })

    }

    const handleOpenkaidanClick = (item) => {
      // console.log(item);
      router.push({
        path: '/main/order/billing',
        query: {
          orderNo: item.orderNo,
          userId: item.userId,
          phone: item.userMobile
        }
      })
    }

    const handleUserClick = item => {
      router.push({
        path: '/main/customer/detail',
        query: {
          userId: item.userId,
          phone: item.userMobile
        }
      })
    }

    return {
      handleUserClick,
      handleOpenkaidanClick,
      orderTitleInfoList,
      handleDetailClick,
      UPLOAD_BASE_URL
    }

  }
})
</script>

<style scoped lang="less">
.order-tabel {
  margin-top: 20px;
  font-size: 12px;

  .user-name {
    cursor: pointer;
    color: rgb(64, 158, 255) !important;
  }

  .state {
    display: flex;
    flex-direction: column;
    justify-content: center;

    span {
      display: inline-block;
      margin-bottom: 3px;
    }
  }

  .buy-shop {
    flex-direction: column;
    justify-content: center;

    & > div {
      margin-bottom: 4px;
    }
  }

  .teacher {
    flex-direction: column;
    display: flex;
    // align-items: center;
    justify-content: center;
    align-items: flex-start !important;

    & > div {
      margin: 6px 0;
    }
  }

  .handle {
    display: flex;
    flex-wrap: wrap;

    &:deep(.el-button) {
      margin: 4px 4px;
    }
  }

  .title {
    display: flex;
    color: rgba(80, 80, 80, 1);
    background-color: rgba(239, 239, 239, 1);
    height: 48px;
    align-items: center;
    padding: 0 10px;
    // justify-content: space-between;

    div {
      flex: 1;
      font-weight: 550;
      // text-align: center;
    }
  }

  .order-info-list {
    // padding: 0 10px;
    .order-info-item {
      margin: 10px 0;

      .top {
        padding: 0 10px;
        display: flex;
        color: rgba(80, 80, 80, 1);
        background-color: rgba(239, 239, 239, 1);
        font-size: 12px;
        height: 35px;
        align-items: center;
        justify-content: space-between;

        .left {
          display: flex;
          color: #666666;

          .buy-time {
            margin-right: 20px;
          }
        }

        .right {
          color: rgba(42, 130, 228, 1);
          font-size: 12px;
          cursor: pointer;
        }
      }

      .bottom {
        display: flex;

        .avatar-price-buynum {
          display: flex;
          flex-direction: column;
          flex: 3;
          align-items: stretch;
          padding-left: 0px;

          .avatar-price-buynum-item {
            border-top: 1px solid var(--borderColor);
            display: flex;
            flex: 1;

            .avatar {
              padding: 0 10px;
            }

            .but-num {
              padding: 0 10px;
            }

            .price {
              div {
                width: 100%;
                padding: 0 10px;
              }
            }

            & > div {
              flex: 1;
            }
          }

          .price,
          .but-num {
            display: flex;
            align-items: center;

            & > div {
              padding: 10px 0;
            }
          }
        }

        .price {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
          border-left: 1px solid var(--borderColor);
          border-right: 1px solid var(--borderColor);
          // align-items: center;
          // justify-content: center;
        }

        .price,
        .total-price {
          color: #ff6600;
        }

        .total-price {
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        .pay-type {
          font-size: 12px;
          margin-top: 2px;
        }

        & > div {
          display: flex;
          flex: 1;
          padding-left: 10px;
          // justify-content: center;
          border-right: 1px solid var(--borderColor);
          border-bottom: 1px solid var(--borderColor);
          align-items: center;
          color: rgba(80, 80, 80, 1);
          font-size: 12px;
          min-height: 55px;
        }

        .avatar {
          display: flex;
          flex-direction: column;
          border-left: 1px solid var(--borderColor);
          align-items: flex-start;

          .serveAvatar-item {
            display: flex;
            padding: 10px 0;
            // border-bottom: 1px solid var(--borderColor) ;
          }

          .serveAvatar-item:last-child {
            border-bottom: none;
          }

          img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
          }
        }

        .goods_name {
          display: flex;
          flex-direction: column;

          .itemName {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .el-pagination {
    margin-top: 10px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>

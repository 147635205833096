<template>
  <div class="order-list">
    <breadcrumb :breadcrumbList="breadcrumbList"></breadcrumb>
    <div class="list-content">
      <order-list></order-list>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { breadcrumbList } from './config'
import OrderList from './cpns/order-list/order-list.vue'
export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    OrderList
  },
  setup() {


    return {
      breadcrumbList
    }

  }
})
</script>

<style scoped lang="less">
.order-list {
  .list-content {
    margin: 10px 20px 20px 20px;
    padding: 20px;
    background-color: white;
    min-width: 960px;
  }
}
</style>





<template>
  <top-content @searchChange="searchChange"></top-content>
  <div class="order-list" v-loading.fullscreen.lock="loading" element-loading-text="数据加载中，请稍等……">
    <order-tabel :category="3" :orderList="allOrderList"></order-tabel>
    <div class="el-pagination">
      <el-pagination v-model:currentPage="currentPage.current" v-model:page-size="currentPage.size"
        :page-sizes="[10]" layout="total, sizes, prev, pager, next, jumper" :total="Number(allOrderListCount)"
        @current-change="handleCurrentChange($event, 0)" />
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, computed,onUnmounted,onBeforeUnmount, nextTick } from 'vue'
import OrderTabel from './cpns/order-table/order-table'
import TopContent from '../../cpns/top-content/top-content.vue'
import dayjs from 'dayjs'
import { useState } from '@/hooks'
import emitter from '@/utils/eventbus'
import store from '@/store'
import { COMPANY_ID } from '@/common/constant'
import { number } from 'echarts'

export default defineComponent({
  props: {

  },
  components: {
    OrderTabel,
    TopContent
  },
  setup() {
    const currentPage = reactive({
      current: 1,
      size: 10
    })
    const loading = ref(false)


    const activeName = ref('all')
    emitter.on('changeOrderListSearchContent', () => {
      activeName.value = 'all'
    })
    const searchChange = item => {
      currentPage.orderType = item.orderType||undefined
      currentPage.appStatus = item.appStatus||undefined
      currentPage.startDate = item.startDate||undefined
      currentPage.endDate = item.endDate||undefined
      currentPage.shopId = item.shopId||undefined
      currentPage.source = item.source||undefined
      currentPage.payStatus = item.payStatus||undefined
      currentPage.keyword = item.keyword||undefined
      currentPage.payWay = item.paykind||undefined
      getOrderList()
    }


    const handleCurrentChange = (page, type) => {
      if (type == 0) {
        currentPage.current=page
        getOrderList()
      } 
    }

    const allOrderList = computed(() => store.state.order.allOrderList)
    const allOrderListCount = computed(() => store.state.order.allOrderListCount)


    


    const getOrderList = () => {
      loading.value = true
      store.dispatch('order/getOrderListAction',currentPage)
      setTimeout(() => {
        loading.value = false
      }, 1000)
    }
    watch(() => currentPage.allpagesize, (n) => {
      emitter.emit('changeOrderListPageInfo', n)
    }, {
      deep: true
    })

    onUnmounted(()=>{
      emitter.off('changeOrderListSearchContent')
    })
    onBeforeUnmount(() => {
      store.commit('order/changeallOrderList', [])
      store.commit('order/changeallOrderListCount', 0)
    });

    const getThreeMonthAgoDate = () => {
      let threeMonthsAgoDate = dayjs(new Date()).subtract(3, "months").format("YYYY-MM-DD");
      return threeMonthsAgoDate;
    }

    const initPage = () => {
      currentPage.startDate = getThreeMonthAgoDate()
      currentPage.endDate = dayjs(new Date()).format("YYYY-MM-DD")
      getOrderList()
    }

    initPage()


    return {
      allOrderList,
      currentPage,
      handleCurrentChange,
      allOrderListCount,
      activeName,
      searchChange,
      loading
    }

  }
})
</script>

<style scoped lang="less">
.order-list {
  .el-pagination {
    text-align: right;
  }
}
</style>
